




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Collection } from '@/models'
import FuzzySearch from 'fuzzy-search'

@Component({
  watch: {
    value(value: boolean) {
      // @ts-ignore
      if (value) this.$nextTick(() => this.$refs.textField.focus())
    }
  }
})
export default class GlobalSearch extends Vue {
  environmentId!: string
  @Prop({ type: Boolean }) value!: boolean

  rawQuery = ''

  input(value: boolean) {
    this.$emit('input', value)
  }

  get query() {
    return this.rawQuery.trim().toLowerCase()
  }

  get results() {
    if (!this.query) return {}
    const res = {}
    for (const objType in {}) {
      if (
        // @ts-ignore
        !this.masterList[objType].items ||
        // @ts-ignore
        !this.masterList[objType].items.length
      )
        continue
      // @ts-ignore
      const searcher = new FuzzySearch(this.masterList[objType].items, ['key'])
      // @ts-ignore
      const matching = searcher.search(this.query)
      if (matching && matching.length) {
        // @ts-ignore
        res[objType] = {
          // @ts-ignore
          display: this.masterList[objType].display,
          // @ts-ignore
          icon: this.masterList[objType].icon,
          items: matching
        }
      }
    }
    return res
  }
}
