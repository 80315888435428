var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('DashboardAppBar'),_c('AdminNavbar'),(_vm.user)?[_c('v-main',{staticClass:"fill-height background"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-2"},[_c('v-col',{attrs:{"sm":"10","offset-sm":"1"}},[_c('v-card',{staticClass:"card--flex-toolbar"},[_c('v-toolbar',{staticClass:"background-2",attrs:{"flat":""}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("person")]),_c('v-toolbar-title',[_c('h1',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('User.editTitle')))]),_c('h3',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(_vm.user && _vm.user.email)}})])],1),(_vm.$apollo.loading)?_c('Loading'):[_c('v-card-text',[_c('Fields',{attrs:{"schema":{
                    roles: {
                      type: 'select',
                      label: _vm.$t('User.schema.roles.label'),
                      fieldOptions: {
                        multi: true,
                        options: [
                          { value: 'admin', label: _vm.$t('User.schema.roles.options.admin') },
                          {
                            value: 'superAdmin',
                            label: _vm.$t('User.schema.roles.options.superAdmin')
                          }
                        ]
                      }
                    },
                    environmentsAuthorized: {
                      type: 'select',
                      label: _vm.$t('User.schema.environmentsAuthorized.label'),
                      fieldOptions: {
                        multi: true,
                        options: _vm.environmentsAvailable
                      }
                    }
                  },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{},"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":_vm.$t('User.deleteBtn')},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":_vm.$t('User.undoBtn'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"text":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v(_vm._s(_vm.$t('User.cancelBtn')))]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('User.saveBtn')))])],1)]],2)],1)],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }